import Video from '@/components/cms/Video';

const VideoWrapper = ({ blok }) => (
  <Video
    url={blok?.url}
    cover={blok.cover}
    file={blok.file}
    autoplay={blok?.autoplay}
    controls={blok?.showControls}
    loop={blok?.loop}
    hasBorder={blok.hasBorder}
    borderColor={blok.borderColor}
    borderRadius={blok.borderRadius}
  />
);

export default VideoWrapper;
